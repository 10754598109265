import { createMuiTheme } from "@material-ui/core/styles";

export const materialTheme = createMuiTheme({
  //props
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  //Typography
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
    fontSize: 13,
  },
  //Color palettes
  palette: {
    primary: {
      main: "#556ee6",
    },
    secondary: {
      main: "#74788d",
    },
    success: {
      main: "#34c38f",
    },
    warning: {
      main: "#f46a6a",
    },
    info: {
      main: "#50a5f1",
    },
    text: {
      primary: "#495057",
    },
  },
  //Overrides
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "#ced4da",
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "inherit",
      },
    },
    MuiAccordion: {
      root: {
        backgroundColor: "#fff",
        border: " 0 solid #f6f6f6",
        boxShadow: "0 0.75rem 1.5rem rgb(18 38 63 / 3%)",
        borderRadius: "0.25rem",
        marginBottom: "12px",
        "&:not(:last-child)": {
          borderBottom: 0,
        },
        "&:before": {
          display: "none",
        },
      },
      expanded: {},
    },
    MuiAccordionSummary: {
      root: {
        backgroundColor: "#fff",
        borderBottom: "1px solid #f6f6f6",
        marginBottom: -1,
        minHeight: 56,
        "&$expanded": {
          minHeight: 56,
          borderBottom: "1px solid rgba(0, 0, 0, .125)",
        },
        "&$disabled": {
          opacity: 1,
          backgroundColor: "rgba(0, 0, 0, .03)",
          border: "1px solid rgba(0, 0, 0, .125)",
        },
      },
      content: {
        "&$expanded": {
          margin: "12px 0",
        },
      },
      expanded: {},
    },
    MuiTabs: {
      indicator: {
        backgroundColor: "#495057",
      },
    },
    MuiTab: {
      root: {
        fontFamily: "inherit",
      },
      wrapper: {
        fontFamily: "inherit",
      },
    },
  },
});

import React, { useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../Store/Actions/Auth";
import { useWindowSize } from "../../../Hooks/useWindowSize";
import { useTranslator } from "../../../Hooks/useTranslator";

function Sidebar(props) {
  const { open, onClose } = props;
  const t = useTranslator();
  const { width } = useWindowSize();
  const { isAdmin } = useSelector((store) => store.auth);
  const [openedSubNav, setopenedSubNav] = useState(null);
  const dispatch = useDispatch();
  const routes = useMemo(
    () => [
      {
        name: "Dashboard",
        path: "/",
        iconClass: "mdi mdi-home",
        show: true,
      },
      {
        name: "Employers",
        path: "/Employers",
        iconClass: "mdi mdi-briefcase-account",
        show: isAdmin,
      },
      {
        name: "Job Posts",
        path: "/JobPosts",
        iconClass: "mdi mdi-text-box-multiple-outline",
        subRoutes: [
          {
            name: "Job Master Data",
            path: "/MasterData",
            show: isAdmin,
          },
        ],
        show: true,
        showSubRoutes: isAdmin,
      },
      {
        name: "Applicants",
        path: "/Applicants",
        iconClass: "mdi mdi-account-supervisor",
        show: true,
      },
      {
        name: "Calendar",
        path: "/Calendar",
        iconClass: "mdi mdi-calendar-month-outline",
        show: true,
      },
    ],
    [isAdmin]
  );

  // Handle logout
  const handleLogout = React.useCallback(() => dispatch(logout()), [dispatch]);

  // Handle sidebar close
  const handleClose = React.useCallback(
    () => open && width < 992 && onClose(),
    [open, onClose, width]
  );

  // Handle sub navigation open/close
  const handleSubNavToggle = React.useCallback((event) => {
    event.stopPropagation();
    const { dataset } = event.target;
    const { routeindex } = dataset;
    setopenedSubNav((prevState) =>
      prevState === parseInt(routeindex) ? null : parseInt(routeindex)
    );
  }, []);

  return (
    <div className={`vertical-menu d-block ${open ? "" : "sidebar-close"}`}>
      <div data-simplebar className="h-100">
        <div
          id="sidebar-menu"
          className="h-100 d-flex flex-column justify-content-between "
        >
          <div>
            <ul className="metismenu list-unstyled" id="side-menu">
              {routes.map(
                (route, index) =>
                  route.show && (
                    <li key={route.path}>
                      <div className="d-flex justify-content-between align-items-center my-3">
                        <NavLink
                          to={route.path}
                          className="waves-effect  "
                          activeClassName="active-tab"
                          onClick={handleClose}
                          exact={route.path === "/"}
                        >
                          <i className={`mdi mdi-${route.iconClass}`}></i>
                          <span>{t(route.name)}</span>
                        </NavLink>
                        {route.showSubRoutes && route.subRoutes.length > 0 && (
                          <i
                            className={`fas fa-angle-${
                              openedSubNav === index ? "up" : "down"
                            } mr-3 text-secondary font-size-16 cursor-pointer`}
                            role="button"
                            data-routeindex={index}
                            onClick={handleSubNavToggle}
                          ></i>
                        )}
                      </div>
                      {route.showSubRoutes && route.subRoutes.length > 0 && (
                        <ul
                          className={`sub-menu mm-collapse ${
                            openedSubNav === index ? "mm-show" : ""
                          }`}
                        >
                          {route.subRoutes.map(
                            (subPath) =>
                              subPath.show && (
                                <li key={subPath.path}>
                                  <NavLink
                                    to={route.path + subPath.path}
                                    className="waves-effect  "
                                    activeClassName="active-tab"
                                    onClick={handleClose}
                                  >
                                    {t(subPath.name)}
                                  </NavLink>
                                </li>
                              )
                          )}
                        </ul>
                      )}
                    </li>
                  )
              )}
            </ul>
          </div>
          <div className="d-none d-sm-block">
            <ul className="metismenu list-unstyled my-3" id="side-menu">
              <li>
                <NavLink
                  to="/Profile"
                  className="waves-effect"
                  activeClassName="active-tab"
                  exact
                  onClick={handleClose}
                >
                  <i className="mdi mdi-account-circle mdi-18px align-middle mr-1"></i>
                  <span>{t("Profile")}</span>
                </NavLink>
              </li>
            </ul>
            <ul className="metismenu list-unstyled my-3" id="side-menu">
              <li>
                <NavLink
                  to={"/"}
                  className="waves-effect"
                  exact
                  onClick={handleLogout}
                >
                  <i className="mdi mdi-logout mdi-18px align-middle mr-1 "></i>
                  <span>{t("Logout")}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;

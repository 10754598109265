import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ProfileCard from "./ProfileCard";
import StatCard from "./StatCard";
import { getAccounts, accountTypes } from "../../Store/Actions/Account";
import { useTranslator } from "../../Hooks/useTranslator";
import ReactECharts from "echarts-for-react";
import AttributesTable from "../Common/Table/AttributesTable";
// import moment from "moment";

function Dashpoard() {
  const dispatch = useDispatch();
  const t = useTranslator();
  const { username, id, email, isAdmin } = useSelector((store) => store.auth);

  const { employers } = useSelector((store) => store.account);

  const TotaleEmployer = employers.list.length;
  const TotalJobPost = 0;
  const TotalApplicants = 0;

  //for Graph letter needed
  // let xData = [];
  // employers.list.forEach((row) => {
  // 	xData.push(moment(row.created).format("MM-YYYY"));
  // });
  // let uniquedata = [...new Set(xData)];

  // let yData = [];
  // employers.list.forEach((row) => {
  // 	yData.push(row);
  // });
  // console.log("yData", yData);

  useEffect(() => {
    isAdmin && dispatch(getAccounts(accountTypes.employer));
  }, [isAdmin, dispatch]);

  const userAttributesMap = React.useMemo(
    () => [
      {
        label: "ID",
        field: "id",
      },
      {
        label: "E-mail",
        field: "email",
      },
    ],
    []
  );

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-between">
            <h4 className="mb-0 font-size-18">{t("Dashboard")}</h4>
          </div>
        </div>
      </div>
      <div className="row">
        <div className={`col-xl-${isAdmin ? "4" : "6"}`}>
          <ProfileCard onDashboard name={username} id={id} />
          <AttributesTable
            title="Personal Information"
            attributeMap={userAttributesMap}
            attributes={{ id, email }}
          />
        </div>
        <div className={`col-xl-${isAdmin ? "8" : "6"}`}>
          <div className="row">
            {isAdmin && (
              <StatCard
                title="Total Employers"
                count={TotaleEmployer && TotaleEmployer}
                iconClass="mdi mdi-briefcase-account font-size-24"
              />
            )}
            <StatCard
              title="Total Job Posts"
              count={TotalJobPost}
              className={!isAdmin && "col-md-6"}
              iconClass="mdi mdi-text-box-multiple-outline font-size-24"
            />
            <StatCard
              title="Total Applicants"
              count={TotalApplicants}
              className={!isAdmin && "col-md-6"}
              iconClass="mdi mdi-account-supervisor font-size-24"
            />
          </div>
          {isAdmin && TotaleEmployer ? (
            <div className="card">
              <div className="card-body">
                <div className="clearfix"></div>
                <div dir="ltr">
                  <ReactECharts
                    option={{
                      tooltip: {
                        trigger: "axis",
                        axisPointer: {
                          type: "shadow",
                        },
                      },
                      legend: {
                        data: [t("Employers"), t("Job Posts"), t("Applicants")],
                      },
                      xAxis: {
                        type: "category",
                        data: [t("Counts")],
                      },
                      yAxis: {
                        type: "value",
                      },
                      series: [
                        {
                          name: t("Employers"),
                          type: "bar",
                          barGap: 0,
                          //label: labelOption,
                          emphasis: {
                            focus: t("Employers"),
                          },
                          data: [TotaleEmployer],
                        },
                        {
                          name: t("Job Posts"),
                          type: "bar",
                          //label: labelOption,
                          emphasis: {
                            focus: t("Job Posts"),
                          },
                          data: [TotalJobPost],
                        },
                        {
                          name: t("Applicants"),
                          type: "bar",
                          //label: labelOption,
                          emphasis: {
                            focus: "series",
                          },
                          data: [TotalApplicants],
                        },
                      ],
                    }}
                    style={{ height: "300px", width: "100%" }}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default Dashpoard;

import React from "react";
import { useTranslator } from "../../../Hooks/useTranslator";

function StatCard(props) {
  const { title, count, iconClass, className } = props;
  const t = useTranslator();
  return (
    <div className={`col-md-4 ${className}`}>
      <div className="card mini-stats-wid">
        <div className="card-body">
          <div className="media">
            <div className="media-body">
              <p className="text-muted font-weight-medium">{t(title)}</p>
              <h4 className="mb-0">{count || t("not_available")}</h4>
            </div>

            <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
              <span className="avatar-title">
                <i className={iconClass}></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StatCard;

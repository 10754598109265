import React from "react";

function FileDownload() {
  return (
    <div className="download-file-animate-icon">
      <div>
        <svg style={{ width: "120px" }} viewBox="0 0 1000 1000">
          <title>download</title>
          <g className="download-file-animate-icon-template">
            <path d="M675.18,899.16h-425a50,50,0,0,1-50-50v-700a50,50,0,0,1,50-50H750a50,50,0,0,1,50,50V773.89" />
          </g>
          <g className="download-file-animate-icon-downloadarrow">
            <line x1="500" y1="498.07" x2="500" y2="704.33" />
            <polyline points="424.52 623.87 500 705.31 574.45 623.8" />
          </g>
          <g className="download-file-animate-icon-downloadtray">
            <polyline points="623.98 703.82 623.98 738.61 623.96 738.75 376.51 738.75 376.33 738.64 376.33 703.83" />
            <path d="M675.12,899.15l125-125Z" />
            <path d="M800,774.13l-74.87,0a50,50,0,0,0-50,50l0,74.93" />
          </g>
        </svg>
      </div>
    </div>
  );
}

export default FileDownload;

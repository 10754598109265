export const jobHeaders = [
  { label: "JOB ID", key: "id" },
  { label: "EMPLOYER ID", key: "employerId" },
  { label: "EMPLOYER NAME", key: "employerName" },
  { label: "SUMMARY", key: "summary" },
  { label: "DESCRIPTION", key: "description" },
  { label: "RESPONSIBILITIES", key: "responsibilityList" },
  { label: "JOB TYPE", key: "jobType" },
  { label: "JOB CATEGORY", key: "jobCategory" },
  { label: "MIN SALARY", key: "minSalary" },
  { label: "MAX SALARY", key: "maxSalary" },
  { label: "EXPERIENCE", key: "minExperienceInYears" },
  { label: "HOURS/WEEK", key: "hoursPerWeek" },
  { label: "SKILLS", key: "skillList" },
  { label: "QUALIFICATIONS", key: "qualificationList" },
  { label: "JOB LOCATIONS", key: "locationList" },
  { label: "APPLICANT SITES", key: "applicantSiteList" },
  { label: "FULFILLED", key: "isFulfilled" },
  { label: "CREATED", key: "created" },
  { label: "LAST UPDATE", key: "lastUpdated" },
];

export const applicantHeaders = [
  { label: "ID", key: "id" },
  { label: "NAME", key: "name" },
  { label: "EMAIL", key: "email" },
  { label: "SUMMARY", key: "applicantDetail.summary" },
  { label: "EXPERIENCE", key: "applicantDetail.experience" },
  { label: "CONTACT", key: "applicantDetail.contactNumber" },
  { label: "CURRENT SALARY", key: "applicantDetail.currentSalary" },
  { label: "EXPECTED SALARY", key: "applicantDetail.expectedSalary" },
  { label: "JOB CATEGORY", key: "applicantDetail.jobCategory" },
  { label: "SKILLS", key: "applicantDetail.skillList" },
  { label: "QUALIFIACTIONS", key: "applicantDetail.qualificationList" },
  { label: "JOB LOCATIONS", key: "applicantDetail.locationList" },
  { label: "DOB", key: "applicantDetail.dateOfBirth" },
  { label: "ADDRESS", key: "applicantDetail.address" },
  { label: "ZIP CODE", key: "applicantDetail.zipCode" },
  { label: "APPLICANT SITE", key: "applicantSite" },
  { label: "APPROVED ON", key: "approved" },
  { label: "CREATED ON", key: "created" },
  { label: "EMAIL VERIFIED", key: "isEmailVerified" },
  { label: "LOGIN ALLOWED", key: "isLoginAllowed" },
  { label: "ROLE", key: "role" },
  { label: "LAST UPDATED", key: "updated" },
];

export const employerHeaders = [
  { label: "ID", key: "id" },
  { label: "NAME", key: "name" },
  { label: "EMAIL", key: "email" },
  { label: "DESCRIPTION", key: "employerDetail.description" },
  { label: "CONTACT", key: "employerDetail.contactNumber" },
  { label: "ADDRESS", key: "employerDetail.address" },
  { label: "ZIP CODE", key: "employerDetail.zipCode" },
  { label: "APPROVED ON", key: "approved" },
  { label: "CREATED ON", key: "created" },
  { label: "EMAIL VERIFIED", key: "isEmailVerified" },
  { label: "LOGIN ALLOWED", key: "isLoginAllowed" },
  { label: "ROLE", key: "role" },
  { label: "LAST UPDATED", key: "updated" },
];

import { Api } from "../Api";
import { setAlert, unsetAlert, failureAlert } from "../App";
import {
  onGetAccountsInit,
  onGetAccountsSuccess,
  onGetAccountsFail,
  accountTypes,
} from "../Account";
import { jobHeaders } from "../../../Configs/CSV/";

// Get Jobs
export const GET_JOBS_INIT = "GET_JOBS_INIT";
export const GET_JOBS_LOADING = "GET_JOBS_LOADING";
export const GET_JOBS_SUCCESS = "GET_JOBS_SUCCESS";
export const GET_JOBS_FAIL = "GET_JOBS_FAIL";

export const getJobs = (params) =>
  Api({
    url: "/Jobs/Auth",
    method: "GET",
    data: params,
    onInit: params.PageNo === 1 ? onGetJobsInit : onGetJobsLoading,
    onSuccess: onGetJobsSuccess,
    onFailure: onGetJobsFail,
  });
export const onGetJobsInit = () => ({
  type: GET_JOBS_INIT,
});
const onGetJobsLoading = () => ({
  type: GET_JOBS_LOADING,
});
export const onGetJobsSuccess = (response) => ({
  type: GET_JOBS_SUCCESS,
  data: response,
});
export const onGetJobsFail = (response) => ({
  type: GET_JOBS_FAIL,
  data: response,
});

// Get Job Details
export const GET_JOB_DETAILS_INIT = "GET_JOB_DETAILS_INIT";
export const GET_JOB_DETAILS_SUCCESS = "GET_JOB_DETAILS_SUCCESS";
export const GET_JOB_DETAILS_FAIL = "GET_JOB_DETAILS_FAIL";
export const getJobDetails = (id) =>
  Api({
    url: `/Jobs/auth/${id}`,
    method: "GET",
    onInit: onGetJobDetailsInit,
    onSuccess: onGetJobDetailsSuccess,
    onFailure: onGetJobDetailsFail,
  });
export const onGetJobDetailsInit = () => ({
  type: GET_JOB_DETAILS_INIT,
});
const onGetJobDetailsSuccess = (response) => ({
  type: GET_JOB_DETAILS_SUCCESS,
  data: response,
});
const onGetJobDetailsFail = (response) => ({
  type: GET_JOB_DETAILS_FAIL,
  data: response,
});

// Post Job
export const postJob = (data) =>
  Api({
    url: "/Jobs/Auth",
    method: "POST",
    data: data,
    onInit: postJobInit,
    onSuccess: postJobSuccess,
    onFailure: postJobFailure,
  });
const postJobInit = () =>
  setAlert({
    type: "loading",
    message: `Adding %%JOBPOST%%`,
  });
const postJobSuccess = () => (dispatch) => {
  dispatch(
    setAlert({
      type: "success",
      message: `%%JOBPOST%% added`,
      onOk: () => dispatch(unsetAlert()),
    })
  );
};
const postJobFailure = () => failureAlert();

//Update Job
export const updateJob = (data, jobId, onSuccess) =>
  Api({
    url: `/Jobs/Auth/${jobId}`,
    method: "PUT",
    data: data,
    onInit: updateJobInit,
    onSuccess: updateJobSuccess(onSuccess),
    onFailure: updateJobFailure,
  });
const updateJobInit = () =>
  setAlert({
    type: "loading",
    message: `Updating %%JOBPOST%%`,
  });
const updateJobSuccess = (onSuccess) => () => (dispatch) => {
  onSuccess();
  dispatch(
    setAlert({
      type: "success",
      message: `%%JOBPOST%% Updated`,
      onOk: () => dispatch(unsetAlert()),
    })
  );
};
const updateJobFailure = () => failureAlert("Job update failed !");

//Delete Job
export const DELETE_JOB = "DELETE_JOB";
export const deleteJob = (jobId, onSuccess) =>
  Api({
    url: `/Jobs/Auth/${jobId}`,
    method: "DELETE",
    onInit: deleteJobInit,
    onSuccess: deleteJobSuccess(jobId, onSuccess),
    onFailure: deleteJobFailure(jobId),
  });
const deleteJobInit = () =>
  setAlert({
    type: "loading",
    message: `Deleting %%JOBPOST%%`,
  });
const deleteJobSuccess = (jobId, onSuccess) => () => (dispatch) => {
  dispatch({
    type: DELETE_JOB,
    data: jobId,
  });
  dispatch(
    setAlert({
      type: "success",
      message: `%%JOBPOST%% Deleted`,
      onOk: () => dispatch(unsetAlert()),
    })
  );
  if (onSuccess) onSuccess();
};
const deleteJobFailure = () => failureAlert("Job update failed !");

// Get Job Metadata
export const GET_JOB_META_SUCCESS = "GET_JOB_META_SUCCESS";
export const GET_JOB_META_FAIL = "GET_JOB_META_FAIL";

export const getJobMeta = (params) =>
  Api({
    url: "/Meta/auth",
    data: params ? params : { NeedAll: true },
    method: "GET",
    onSuccess: onGetJobMetaSuccess,
  });
const onGetJobMetaSuccess = (response) => ({
  type: GET_JOB_META_SUCCESS,
  data: response,
});

// Add Job MetaData
export const ADD_JOB_META_SUCCESS = "ADD_JOB_META_SUCCESS";
export const addJobMeta = (data) =>
  Api({
    url: `/Meta/auth?metaType=${data.id}`,
    data: data.data,
    method: "POST",
    onInit: addJobMetaInit(data.display),
    onSuccess: addJobMetaSuccess(data.display),
    onFailure: addJobMetaFail(data.display),
  });
const addJobMetaInit = (name) => () =>
  setAlert({
    type: "loading",
    message: `Adding ${name} ...`,
  });
const addJobMetaSuccess = (name) => (response) => (dispatch) => {
  dispatch(
    setAlert({
      type: "success",
      message: `${name} Added !`,
      onOk: () => dispatch(unsetAlert()),
    })
  );
  dispatch(getJobMeta());
};
const addJobMetaFail = (name) => () => (dispatch) =>
  dispatch(failureAlert(`Adding ${name} failed !`));

// Update Meta Data
export const UPDATE_JOB_META = "UPDATE_JOB_META";
export const updateJobMeta = (data) =>
  Api({
    url: "/Meta/auth",
    method: "PUT",
    data: {
      metaType: data.dataId,
      oldItem: data.oldValue,
      newItem: data.newValue,
    },
    onInit: onUpdateJobMetaInit(data),
    onSuccess: onUpdateJobMetaSuccess(data),
    onFailure: onUpdateJobMetaFail(data),
  });
const onUpdateJobMetaInit = (data) => () =>
  setAlert({
    type: "loading",
    message: `Updating ${data.display} "${data.oldValue}" to "${data.newValue}" ...`,
  });
const onUpdateJobMetaSuccess = (data) => () => (dispatch) => {
  dispatch(
    setAlert({
      type: "success",
      message: `${data.display} updated !`,
      onOk: () => dispatch(unsetAlert()),
    })
  );
  dispatch({
    type: UPDATE_JOB_META,
    data: data,
  });
};
const onUpdateJobMetaFail = (data) => () => (dispatch) =>
  dispatch(failureAlert(`${data.display} update failed !`));

// Delete job meta
export const DELETE_JOB_META = "DELETE_JOB_META";
export const deleteJobMeta = (data) =>
  Api({
    url: "/Meta/auth",
    method: "DELETE",
    data: {
      metaType: data.dataId,
      item: data.item,
    },
    onInit: deleteJobMetaInit(data),
    onSuccess: deleteJobMetaSuccess(data),
    onFailure: deleteJobMetaFail(data),
  });
const deleteJobMetaInit = (data) => () =>
  setAlert({
    type: "loading",
    message: `Deleting ${data.display} "${data.item}"  ...`,
  });
const deleteJobMetaSuccess = (data) => () => (dispatch) => {
  dispatch(
    setAlert({
      type: "success",
      message: `${data.display} "${data.item}" deleted !`,
      onOk: () => dispatch(unsetAlert()),
    })
  );
  dispatch({
    type: DELETE_JOB_META,
    data: data,
  });
};
const deleteJobMetaFail = (data) => () => (dispatch) =>
  dispatch(failureAlert(`${data.display} "${data.item}" delete failed !`));

// Get list of applicants applied to a job
export const getApplicantListForJob = (jobId) =>
  Api({
    url: `/applyjob/getapplicants/${jobId}`,
    method: "GET",
    onInit: onGetAccountsInit(accountTypes.applicant),
    onSuccess: onGetAccountsSuccess(accountTypes.applicant),
    onFailure: onGetAccountsFail(accountTypes.applicant),
  });

// Set sorting attributes
export const SET_QUERY_PARAMS = "SET_QUERY_PARAMS";
export const setQueyParams = (qureyParams) => ({
  type: SET_QUERY_PARAMS,
  data: qureyParams,
});

// Export Jobs to CSV
export const exportJobs = () =>
  Api({
    url: "/jobs/auth/get-detail-jobs",
    method: "GET",
    onInit: onExportJobsInit,
    onSuccess: onExportSuccess,
    onFailure: onExportFail,
  });
const onExportJobsInit = () =>
  setAlert({
    type: "loading",
    message: "Exporting Jobs",
  });
const onExportSuccess = (data) => (dispatch) =>
  dispatch(
    setAlert({
      type: "csv",
      message: "Exported file is ready for download",
      data: {
        fileData: data,
        filename: `jobs_${new Date(Date.now()).toLocaleDateString()}`,
        fileHeaders: jobHeaders,
      },
      onDownload: () => dispatch(unsetAlert()),
      onCancel: () => dispatch(unsetAlert()),
    })
  );
const onExportFail = () => {
  failureAlert("Unable to export");
};

import React from "react";
import AppRoutes from "./Routes";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { checkAuth } from "./Store/Actions/Auth";

function App() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  // Checking authentication on load
  React.useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  // Scrolling to top when visiting a new page
  React.useEffect(() => {
    window.scroll(0, 0);
  }, [pathname]);

  return (
    <div className="App" id="App">
      <AppRoutes />
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import profileBackground from "../../../assets/images/profile-img.png";
import avatar from "../../../assets/images/avatar.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfilePicture, resetProfilePic } from "../../../Store/Actions/App";
import { useTranslator } from "../../../Hooks/useTranslator";

function ProfileCard(props) {
  const dispatch = useDispatch();
  const t = useTranslator();
  const { profileImage } = useSelector((store) => store.auth);
  const { onDashboard, name, verified, active, id, photoUrl } = props;
  const [profilePictureUrl, setprofilePictureUrl] = useState(null);

  // Setting up profile pic of other users
  useEffect(() => {
    if (!photoUrl) dispatch(getProfilePicture(id));
    else setprofilePictureUrl(photoUrl);
    return () => !photoUrl && dispatch(resetProfilePic());
  }, [dispatch, id, photoUrl]);

  // Setting up profile pic of logged in user
  useEffect(() => {
    if (!photoUrl) setprofilePictureUrl(profileImage);
  }, [profileImage, photoUrl, dispatch]);

  return (
    <div className="card overflow-hidden">
      <div className="bg-soft-primary">
        <div className="row">
          <div className="col-7">
            <div className="text-primary p-3">
              {onDashboard && (
                <h5 className="text-primary">{t("Welcome Back")}</h5>
              )}
            </div>
          </div>
          <div className="col-5 align-self-end">
            <img src={profileBackground} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="row">
          <div className="col-sm-6">
            <div className="avatar-md profile-user-wid mb-2">
              <img
                src={profilePictureUrl ? profilePictureUrl : avatar}
                alt={name}
                className="img-thumbnail rounded-circle"
              />
            </div>
            <h5 className="font-size-15 ">{name}</h5>
          </div>

          <div className="col-sm-6 ">
            <div className="pt-2 mt-2 float-right">
              {onDashboard && (
                <Link to="/Profile">
                  <span className="btn btn-primary waves-effect waves-light btn-sm">
                    {t("View Profile")}{" "}
                    <i className="mdi mdi-arrow-right ml-1"></i>
                  </span>
                </Link>
              )}
              {!onDashboard && (
                <>
                  <span
                    className={`badge badge-soft-${
                      active ? "success" : "danger"
                    }  font-size-12 mr-3`}
                  >
                    {t(active ? "Active" : "Inactive")}
                  </span>
                  <span
                    className={`badge badge-soft-${
                      verified ? "info" : "warning"
                    } font-size-12`}
                  >
                    <i
                      className={`mdi mdi-${
                        verified ? "check" : "alert"
                      }-decagram  mr-2`}
                    ></i>
                    {t(verified ? "Verified" : "Unverified")}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;

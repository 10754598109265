import { Api } from "../Api";
import { setAlert, unsetAlert, failureAlert } from "../App";
import { onGetJobsInit, onGetJobsSuccess, onGetJobsFail } from "../Job";
import { applicantHeaders, employerHeaders } from "../../../Configs/CSV";

// Account Types
export const accountTypes = { employer: "employer", applicant: "applicant" };

// Get Account by ID
export const GET_ACCOUNT_DETAILS_INIT = "GET_ACCOUNT_DETAILS_INIT";
export const GET_ACCOUNT_DETAILS_SUCCESS = "GET_ACCOUNT_DETAILS_SUCCESS";
export const GET_ACCOUNT_DETAILS_FAIL = "GET_ACCOUNT_DETAILS_FAIL";
export const getAccountByID = (id) =>
  Api({
    url: `/Accounts/${id}`,
    method: "GET",
    onInit: resetAccountDetails,
    onSuccess: setAccountDetails,
    onFailure: onGetAccountByIDFail,
  });
export const resetAccountDetails = () => ({
  type: GET_ACCOUNT_DETAILS_INIT,
});
export const setAccountDetails = (response) => ({
  type: GET_ACCOUNT_DETAILS_SUCCESS,
  data: response,
});
const onGetAccountByIDFail = (response) => ({
  type: GET_ACCOUNT_DETAILS_FAIL,
  data: response,
});

//Update Account
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const updateAccount = (id, data) =>
  Api({
    url: `/Accounts/${id}`,
    method: `PATCH`,
    data: data,
    onInit: onUpdateAccountInit,
    onSuccess: onUpdateAccountSuccess,
    onFailure: onUpdateAccountFail,
  });

const onUpdateAccountInit = () => (dispatch) =>
  dispatch(
    setAlert({
      type: "loading",
      message: "Updating %%ACCOUNT%%",
    })
  );
const onUpdateAccountSuccess = (response) => (dispatch) => {
  dispatch(
    setAlert({
      type: "success",
      message: "%%ACCOUNT%% Updated",
      onOk: () => dispatch(unsetAlert()),
    })
  );
  dispatch(lAccountUpdate(response));
};
const lAccountUpdate = (response) => ({
  type: UPDATE_ACCOUNT_SUCCESS,
  data: response,
});
const onUpdateAccountFail = () => (dispatch) => dispatch(failureAlert());

// Get Accounts
export const GET_ACCOUNTS_INIT = "GET_ACCOUNTS_INIT";
export const GET_ACCOUNTS_SUCCESS = "GET_ACCOUNTS_SUCCESS";
export const GET_ACCOUNTS_FAIL = "GET_ACCOUNTS_FAIL";

export const getAccounts = (type, params) =>
  Api({
    url: "/Accounts",
    method: "GET",
    data: { userType: type === accountTypes.employer ? 3 : 2, ...params },
    onInit: onGetAccountsInit(type),
    onSuccess: onGetAccountsSuccess(type),
    onFailure: onGetAccountsFail(type),
  });
export const onGetAccountsInit = (accountType) => () => ({
  type: GET_ACCOUNTS_INIT,
  data: { accountType },
});
export const onGetAccountsSuccess = (accountType) => (response) => ({
  type: GET_ACCOUNTS_SUCCESS,
  data: { response, accountType },
});
export const onGetAccountsFail = (accountType) => (error) => ({
  type: GET_ACCOUNTS_FAIL,
  data: { accountType, error },
});

// Activate Account
export const ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT";
export const activateAccount = (type, id, name) =>
  Api({
    url: `/Accounts/approve/${id}`,
    method: "PUT",
    onInit: onActivateAccountInit(name),
    onSuccess: onActivateAccountSuccess(type, id, name),
    onFailure: onActivateAccountFail,
  });
const onActivateAccountInit = (name) => () =>
  setAlert({
    type: "loading",
    message: `Activating %%${name}%%`,
  });
const onActivateAccountSuccess =
  (accountType, id, name) => () => (dispatch) => {
    dispatch(
      setAlert({
        type: "success",
        message: `%%${name}%% activated`,
        onOk: () => dispatch(unsetAlert()),
      })
    );
    dispatch(lActivateAccount(accountType, id));
  };
const lActivateAccount = (accountType, id) => ({
  type: ACTIVATE_ACCOUNT,
  data: { accountType, id },
});
const onActivateAccountFail = () => (dispatch) => dispatch(failureAlert());

// Deactivate Account
export const DEACTIVATE_ACCOUNT = "DEACTIVATE_ACCOUNT";
export const deactivateAccount = (type, id, name) =>
  Api({
    url: `/Accounts/disable/${id}`,
    method: "PUT",
    onInit: onDeactivateAccountInit(name),
    onSuccess: onDeactiveAccountSuccess(type, id, name),
    onFailure: onDeactivateAccountFail,
  });
const onDeactivateAccountInit = (name) => () =>
  setAlert({
    type: "loading",
    message: `Deactivating %%${name}%%`,
  });
const onDeactiveAccountSuccess =
  (accountType, id, name) => () => (dispatch) => {
    dispatch(
      setAlert({
        type: "success",
        message: `%%${name}%% deactivated`,
        onOk: () => dispatch(unsetAlert()),
      })
    );
    dispatch(lDeactivateAccount(accountType, id));
  };
const lDeactivateAccount = (accountType, id) => ({
  type: DEACTIVATE_ACCOUNT,
  data: { accountType, id },
});
const onDeactivateAccountFail = () => (dispatch) => dispatch(failureAlert());

// Delete Account
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";
export const deleteAccount = (type, id, name) =>
  Api({
    url: `/Accounts/${id}`,
    method: "DELETE",
    onInit: onDeleteAccountInit(name),
    onSuccess: onDeleteAccountSuccess(type, id, name),
    onFailure: onDeleteAccountFail,
  });
const onDeleteAccountInit = (name) => () =>
  setAlert({
    type: "loading",
    message: `Deleting %%${name}%%`,
  });
const onDeleteAccountSuccess = (accountType, id, name) => () => (dispatch) => {
  dispatch(
    setAlert({
      type: "success",
      message: `%%${name}%% deleted`,
      onOk: () => dispatch(unsetAlert()),
    })
  );
  dispatch(lDeleteAccount(accountType, id));
};
const lDeleteAccount = (accountType, id) => ({
  type: DELETE_ACCOUNT,
  data: { accountType, id },
});
const onDeleteAccountFail = () => (dispatch) => dispatch(failureAlert());

// Add Account
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const addAccount = (type, data) => {
  let role, name;
  if(type === accountTypes.employer) {
    role = "Employer";
    name = data.employerDetail.name
  }
  else {
    role = "Applicant";
    name = data.applicantDetail.firstName
  }
  return Api({
    url: "/Accounts",
    method: "POST",
    data: {
      ...data,
      role
    },
    onInit: addAccountInit(name),
    onSuccess: addAccountSuccess(type, name),
    onFailure: addAccountFail(name),
  });
}
const addAccountInit = (name) => () =>
  setAlert({
    type: "loading",
    message: `Adding %%${name}%%`,
  });
const addAccountSuccess = (accountType, name) => (response) => (dispatch) => {
  dispatch(
    setAlert({
      type: "success",
      message: `%%${name}%% Added`,
      onOk: () => dispatch(unsetAlert()),
    })
  );
  dispatch(lAddAccount(accountType, response));
};
const lAddAccount = (accountType, response) => ({
  type: ADD_ACCOUNT,
  data: { accountType, response },
});
const addAccountFail = (name) => () => (dispatch) => dispatch(failureAlert());

// Get applied jobs for an applicant
export const getAppliedJobs = (applicantId, params = {}) =>
  Api({
    url: `/applyjob/getjobs/${applicantId}`,
    method: "GET",
    data: params,
    onInit: onGetJobsInit,
    onSuccess: onGetJobsSuccess,
    onFailure: onGetJobsFail,
  });

// Export Accounts to CSV
export const exportAccounts = (accountType) =>
  Api({
    url: "/accounts/get-detail-accounts",
    method: "GET",
    data: { userType: accountType === accountTypes.employer ? 3 : 2 },
    onInit: onExportAccountssInit(accountType),
    onSuccess: onExportSuccess(accountType),
    onFailure: onExportFail,
  });
const onExportAccountssInit = (accountType) => () =>
  setAlert({
    type: "loading",
    message: `Exporting ${
      accountType === accountTypes.applicant ? "applicants" : "employers"
    }`,
  });
const onExportSuccess = (accountType) => (data) => (dispatch) =>
  dispatch(
    setAlert({
      type: "csv",
      message: "Exported file is ready for download",
      data: {
        fileData: data,
        filename: `${
          accountType === accountTypes.applicant ? "applicants" : "employers"
        }_${new Date(Date.now()).toLocaleDateString()}`,
        fileHeaders:
          accountType === accountTypes.applicant
            ? applicantHeaders
            : employerHeaders,
      },
      onDownload: () => dispatch(unsetAlert()),
      onCancel: () => dispatch(unsetAlert()),
    })
  );
const onExportFail = () => {
  failureAlert("Unable to export");
};

import React from "react";
import { Switch, Route } from "react-router-dom";

import Login from "../../Containers/Login";
import ResetPassword from "../../Containers/ResetPassword";
import VerifyEmail from "../../Containers/VerifyEmail";

function LoginRoute() {
  return (
    <Switch>
      <Route path="/account/reset-password" component={ResetPassword} />
      <Route path="/account/verify-email" component={VerifyEmail} />
      <Route path="/" component={Login} />
    </Switch>
  );
}

export default LoginRoute;

import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import thunk from "redux-thunk";
// Reducers
import AppReducer from "./Reducers/App";
import AccountReducer from "./Reducers/Account";
import AuthReducer from "./Reducers/Auth";
import JobReducer from "./Reducers/Job";
// Cutom middlewares
import { ApiMiddleware } from "./Middlewares/api";

const RootReducer = combineReducers({
  app: AppReducer,
  account: AccountReducer,
  auth: AuthReducer,
  job: JobReducer,
});

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

export const store = createStore(
  RootReducer,
  composeEnhancers(applyMiddleware(ApiMiddleware, thunk))
);

import React, { useCallback, useState, useEffect } from "react";
import { useTranslator } from "../../../../Hooks/useTranslator";
import ValidationInput from "../../ValidationInput";
import { get as getFromObject, set as setInObject, cloneDeep } from "lodash";
import { rules } from "../../../../Utils/InputValidator";
import { formatPrettyDate } from "../../../../Utils";

export const AttributesTable = ({
  attributeMap,
  attributes,
  title,
  onEdit,
  editing,
  onChange,
  withoutCard,
}) => {
  const t = useTranslator();
  const [resetInputError, setresetInputError] = useState(false);

  useEffect(() => {
    setresetInputError((prevState) => !prevState);
  }, [editing]);

  const getAttributeValue = useCallback(
    (attribute) => {
      if (Object.keys(attributes).length === 0) return "";

      const {
        id,
        field,
        subField = null,
        concatWith = " ",
        translate = false,
      } = attribute;

      let attributeValue = getFromObject(attributes, field, null);

      if (attributeValue !== null && translate)
        attributeValue = t(attributeValue);

      switch (attribute.displayType) {
        case attributeDisplayTypes.tags:
          return attributeValue.map((subAttribute, i) => (
            <span
              key={`${id}-${i}`}
              className="badge badge-soft-primary font-size-11 m-1"
            >
              {subField ? subAttribute[subField] : subAttribute}
            </span>
          ));

        case attributeDisplayTypes.csv:
          if (!attributeValue || attributeValue.length === 0) return null;
          return attributeValue.map((subAttribute, i) => (
            <span className="px-1" key={`${id}-${i}`}>
              {subField ? subAttribute[subField] : subAttribute}
              {i < attributeValue.length - 1 && ", "}
            </span>
          ));

        case attributeDisplayTypes.date:
          return editing
            ? attributeValue && attributeValue.split("T")[0]
            : formatPrettyDate(attributeValue);

        case attributeDisplayTypes.concat:
          return attribute.field
            .map((field) => getFromObject(attributes, field))
            .join(concatWith);

        default:
          return attributeValue;
      }
    },
    [attributes, editing, t]
  );

  const handleAttributeValueChange = useCallback(
    (event) => {
      const { value, dataset } = event.target;
      const updatedAtrributes = cloneDeep(attributes);
      onChange(setInObject(updatedAtrributes, dataset.field, value));
    },
    [attributes, onChange]
  );

  return (
    <div className={!withoutCard ? "card" : undefined}>
      {!withoutCard && (
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="card-title m-0">{t(title)}</h4>
            {onEdit && (
              <button
                type="button"
                className="btn btn-primary btn-lg waves-effect waves-light btn-sm"
                onClick={onEdit}
              >
                <i className="fas fa-user-edit"></i>
                <span className="d-none d-sm-inline  ml-2">
                  {t("Edit Details")}
                </span>
              </button>
            )}
          </div>
        </div>
      )}
      <div className="table-responsive">
        <table className="table table-nowrap mb-0">
          <tbody>
            {attributeMap.map((attribute) => (
              <tr key={attribute.field}>
                <th scope="row" className="d-flex flex-wrap text-wrap">
                  {t(attribute.label)}{" "}
                  {editing &&
                    attribute.inputConfig &&
                    attribute.inputConfig.rules &&
                    attribute.inputConfig.rules.findIndex(
                      (rule) => rule.rule === rules.notNull
                    ) >= 0 && <span className="text-danger">*</span>}{" "}
                </th>
                <td>
                  {!editing || !attribute.inputConfig ? (
                    <div className="d-flex flex-wrap text-wrap">
                      {getAttributeValue(attribute)}
                    </div>
                  ) : (
                    <ValidationInput
                      element={attribute.inputConfig.element}
                      type={attribute.inputConfig.type}
                      data-field={attribute.field}
                      value={getAttributeValue(attribute)}
                      onChange={handleAttributeValueChange}
                      rules={attribute.inputConfig.rules}
                      resetInputError={resetInputError}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AttributesTable;

export const attributeDisplayTypes = {
  tags: "tags",
  csv: "csv",
  date: "date",
  concat: "concat",
};

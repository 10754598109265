import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Landing from "../Containers/Landing";

import Login from "./Login";
import Dashboard from "./Dashboard";
import SplashScreen from "../Containers/SplashScreen";
const Employers = React.lazy(() => import("./Employers"));
const JobPosts = React.lazy(() => import("./JobPosts"));
const Applicants = React.lazy(() => import("./Applicants"));
const Calendar = React.lazy(() => import("./Calendar"));
const Profile = React.lazy(() => import("./Account"));

function AppRoutes() {
  const { token, isAdmin, checkingAuth } = useSelector((store) => store.auth);

  if (checkingAuth) return <SplashScreen />;

  if (!Boolean(token))
    return (
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route path="/" component={Login} />
        </Switch>
      </Suspense>
    );
  else
    return (
      <Landing>
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route path="/" exact component={Dashboard} />
            {isAdmin && <Route path="/Employers" component={Employers} />}
            <Route path="/JobPosts" component={JobPosts} />
            <Route path="/Applicants" component={Applicants} />
            <Route path="/Calendar" component={Calendar} />
            <Route path="/Profile" component={Profile} />
            <Redirect from="/" to="/" />
          </Switch>
        </Suspense>
      </Landing>
    );
}

export default AppRoutes;

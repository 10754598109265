import {
  GET_ACCOUNT_DETAILS_INIT,
  GET_ACCOUNT_DETAILS_SUCCESS,
  GET_ACCOUNT_DETAILS_FAIL,
  GET_ACCOUNTS_INIT,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAIL,
  UPDATE_ACCOUNT_SUCCESS,
  ACTIVATE_ACCOUNT,
  DEACTIVATE_ACCOUNT,
  ADD_ACCOUNT,
  DELETE_ACCOUNT,
  accountTypes,
} from "../../Actions/Account";
import { LOGOUT } from "../../Actions/Auth";
import { handleAccountOperations, operations } from "../../../Utils/Accounts";

const initState = {
  accountDetails: "loading",
  employers: {
    list: [],
    loading: false,
    error: null,
  },
  applicants: {
    list: [],
    loading: false,
    error: null,
  },
};

const AppReducer = (state = initState, action) => {
  const { type, data } = action;
  let accountKey, id;
  if (data && data.accountType)
    accountKey =
      data.accountType === accountTypes.employer ? "employers" : "applicants";
  if (data && data.id) id = data.id;

  switch (type) {
    // Account details
    case GET_ACCOUNT_DETAILS_INIT:
      return { ...state, accountDetails: "loading" };
    case GET_ACCOUNT_DETAILS_SUCCESS:
      return { ...state, accountDetails: data };
    case GET_ACCOUNT_DETAILS_FAIL:
      return { ...state, accountDetails: null };

    //Update Account
    case UPDATE_ACCOUNT_SUCCESS:
      return { ...state, accountDetails: { ...state.accountDetails, ...data } };

    // Get Accounts
    case GET_ACCOUNTS_INIT:
      return {
        ...state,
        [accountKey]: {
          ...state[accountKey],
          loading: true,
          list: [],
          error: null,
        },
      };

    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        [accountKey]: { list: data.response, loading: false },
      };
    case GET_ACCOUNTS_FAIL:
      return {
        ...state,
        [accountKey]: { loading: false, error: data.error.message },
      };

    // Activate Account
    case ACTIVATE_ACCOUNT:
      return handleAccountOperations(
        state,
        accountKey,
        id,
        operations.activate
      );

    // Deactivate Account
    case DEACTIVATE_ACCOUNT:
      return handleAccountOperations(
        state,
        accountKey,
        id,
        operations.deactivate
      );

    // Delete Account
    case DELETE_ACCOUNT:
      return handleAccountOperations(state, accountKey, id, operations.remove);

    // Add Account
    case ADD_ACCOUNT: {
      let updatedAccounts = [...state[accountKey].list];
      updatedAccounts.push(data.response);
      return { ...state, [accountKey]: { list: updatedAccounts } };
    }

    // Logout
    case LOGOUT:
      return { ...initState };

    default:
      return state;
  }
};

export default AppReducer;

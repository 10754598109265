import React from "react";

function Confirm() {
  return (
    <div
      className="swal2-icon swal2-question swal2-animate-question-icon"
      style={{ display: "flex" }}
    ></div>
  );
}

export default Confirm;

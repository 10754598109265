export const handleAccountOperations = (state, accountKey, id, operation) => {
  let newState = { ...state };
  if (state[accountKey].list.length > 0) {
    newState = {
      ...newState,
      [accountKey]: {
        list: updateAccountList(state[accountKey].list, id, operation),
      },
    };
  }
  if (state.accountDetails && state.accountDetails !== "loading") {
    newState = {
      ...newState,
      accountDetails: {
        ...state.accountDetails,
        approved: operation === operations.activate ? true : false,
      },
    };
  }
  return newState;
};

const updateAccountList = (list, id, operation) => {
  let updatedAccounts = [...list];
  let updateIndex = updatedAccounts.findIndex(
    (account) => parseInt(id) === parseInt(account.id)
  );
  if (updateIndex >= 0) {
    if (operation === operations.remove) updatedAccounts.splice(updateIndex, 1);
    else if (
      operation === operations.activate ||
      operation === operations.deactivate
    ) {
      updatedAccounts[updateIndex].approved =
        operation === operations.activate ? true : false;
    }
    return updatedAccounts;
  } else return list;
};

export const operations = {
  activate: "ACTIVATE",
  deactivate: "DEACTIVATE",
  remove: "remove",
};

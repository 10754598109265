import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { logout } from "../../../../Store/Actions/Auth";
import { useTranslator } from "../../../../Hooks/useTranslator";

function ProfileDropdown() {
  const dispatch = useDispatch();
  const t = useTranslator();
  const { push } = useHistory();
  const { username } = useSelector((store) => store.auth);

  const [show, setshow] = useState(false);

  const handleToggle = useCallback(() => {
    setshow((prevState) => !prevState);
  }, []);

  const handleClose = useCallback(() => {
    setshow(false);
  }, []);

  const handleLogout = useCallback(() => {
    dispatch(logout());
    push("/");
  }, [dispatch, push]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className="dropdown d-inline-block">
        <button
          type="button"
          className="btn header-item waves-effect d-flex align-items-center"
          id="page-header-user-dropdown"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={handleToggle}
        >
          <div className="avatar-xs m-1">
            <span className="avatar-title rounded-circle bg-soft-secondary text-dark font-size-16">
              {username && username[0].toUpperCase()}
            </span>
          </div>

          <span className="d-none d-xl-inline-block ml-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div
          className={`dropdown-menu dropdown-menu-right ${show ? "show" : ""}`}
        >
          <span onClick={handleClose}>
            <NavLink to="/Profile" className="dropdown-item">
              <i className="mdi mdi-account-circle mdi-18px align-middle mr-1"></i>
              {t("Profile")}
            </NavLink>
          </span>
          <div className="dropdown-divider"></div>
          <button
            className="btn dropdown-item text-danger"
            onClick={handleLogout}
          >
            <i className="mdi mdi-logout mdi-18px align-middle mr-1 text-danger"></i>{" "}
            {t("Logout")}
          </button>
        </div>
      </div>
    </ClickAwayListener>
  );
}

export default ProfileDropdown;

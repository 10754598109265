import React from "react";
import Loader from "../../assets/loader";

function SplashScreen() {
  return (
    <div className="login-container bg-img d-flex flex-column align-items-center justify-content-center">
      <h1 className="text-secondary">Job Portal</h1>
      <Loader />
    </div>
  );
}

export default SplashScreen;

import React, { useState, useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertBox from "./AlertBox";
import { rules } from "../../Utils/InputValidator";
import { login, forgotPassword, register } from "../../Store/Actions/Auth";
import ValidationInput from "../../Components/Common/ValidationInput";

function Login() {
  const { error, loading } = useSelector((store) => store.auth);
  const dispatch = useDispatch();
  const defaultInputs = useMemo(
    () => [
      {
        id: "user-name",
        element: "input",
        type: "text",
        value: "",
        placeholder: "Enter your name",
        label: "Name",
        validations: [{ rule: rules.notNull }],
        valid: false,
        show: false,
      },
      {
        id: "user-email",
        element: "input",
        type: "text",
        value: "",
        placeholder: "Enter your email",
        label: "Email",
        validations: [{ rule: rules.notNull }, { rule: rules.email }],
        valid: false,
        show: true,
      },
      {
        id: "user-password",
        element: "input",
        type: "password",
        value: "",
        placeholder: "Enter password",
        label: "Password",
        validations: [
          { rule: rules.notNull },
          { rule: rules.password },
          { rule: rules.minLength, length: 6 },
        ],
        valid: false,
        show: true,
      },
    ],
    []
  );

  const [inputs, setinputs] = useState(defaultInputs);
  const [isPageValid, setisPageValid] = useState(false);
  const [isRegister, setisRegister] = useState(false);
  const [isResetPassword, setisResetPassword] = useState(false);
  const [alert, setalert] = useState({ open: false, message: "" });
  const [resetInputErrors, setresetInputErrors] = useState(false);

  const handleInputChange = React.useCallback(
    (event, isValid) => {
      const newValue = event.target.value;
      const inputIndex = inputs.findIndex(
        (input) => input.id === event.target.id
      );
      const newInput = { ...inputs[inputIndex] };
      newInput.value = newValue;
      newInput.valid = isValid;

      let newInputs = [...inputs];
      newInputs[inputIndex] = newInput;
      let status = true;
      for (let i = 0; i < newInputs.length; i++) {
        if (newInputs[i].show) {
          status = status && newInputs[i].valid;
        }
      }
      setisPageValid(status);
      setinputs(newInputs);
    },
    [inputs]
  );

  const loginToogleHandler = React.useCallback(
    (event) => {
      setresetInputErrors((prevState) => !prevState);
      if (isResetPassword) setisResetPassword(false);
      else setisRegister((prevState) => !prevState);
    },
    [isResetPassword]
  );

  useEffect(() => {
    let updatedInputs = [...defaultInputs];
    if (isResetPassword) {
      updatedInputs[0].show = false;
      updatedInputs[2].show = false;
    } else {
      updatedInputs[2].show = true;
    }
    setinputs(updatedInputs);
  }, [isResetPassword, defaultInputs]);

  useEffect(() => {
    let updatedInputs = [...defaultInputs];
    if (isRegister) {
      updatedInputs[0].show = true;
    } else {
      updatedInputs[0].show = false;
    }
    setinputs(updatedInputs);
  }, [isRegister, defaultInputs]);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      let reqBody;
      if (!isRegister && !isResetPassword) {
        reqBody = {
          email: inputs[1].value,
          password: inputs[2].value,
        };
        return dispatch(login(reqBody));
      } else if (isResetPassword) {
        reqBody = {
          email: inputs[1].value,
        };
        return dispatch(forgotPassword(reqBody)).then((res) => {
          setalert({
            open: true,
            message: res.message,
          });
        });
      } else if (isRegister) {
        reqBody = {
          employerDetail: {
            name: inputs[0].value,
          },
          email: inputs[1].value,
          password: inputs[2].value,
          confirmPassword: inputs[2].value,
          acceptTerms: true,
          role: "Employer",
        };
        return dispatch(register(reqBody)).then((res) => {
          setalert({
            open: true,
            message: res.message,
          });
        });
      }
    },
    [dispatch, inputs, isRegister, isResetPassword]
  );

  const handleKeyPress = useCallback(
    (event) => {
      if (
        (event.which === 13 || event.keycode === 13) &&
        isPageValid &&
        event.target.id === "el3"
      )
        handleSubmit();
    },
    [isPageValid, handleSubmit]
  );

  return (
    <div className="login-container bg-img">
      <div className="account-pages pt-sm-5">
        <div className="container">
          <div className="row justify-content-center mt-lg-4">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card overflow-hidden">
                <div className="bg-soft-primary">
                  <div className="row">
                    <div className="col-12">
                      <div className="text-primary p-4">
                        <h5 className="text-primary mb-0">
                          <i className="mdi mdi-lock  mr-2"></i>
                          {isRegister
                            ? "Request Access"
                            : isResetPassword
                            ? "Reset Password"
                            : "Sign in to Job Portal Back Office"}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body ">
                  <div className="p-2">
                    {error && <div className="text-danger mb-3">{error}</div>}
                    <form className="form-horizontal">
                      {inputs.map(
                        (input) =>
                          input.show && (
                            <div className="form-group" key={input.id}>
                              <label htmlFor="username">{input.label}</label>
                              <ValidationInput
                                type={input.type}
                                id={input.id}
                                value={input.value}
                                placeholder={input.placeholder}
                                onChange={handleInputChange}
                                onKeyPress={handleKeyPress}
                                disabled={loading}
                                rules={input.validations}
                                resetInputError={resetInputErrors}
                              />
                            </div>
                          )
                      )}

                      <div className="mt-3">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          onClick={handleSubmit}
                          disabled={!isPageValid || loading}
                        >
                          {loading && (
                            <>
                              <span
                                className="spinner-grow spinner-grow-sm mr-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span
                                className="spinner-grow spinner-grow-sm mr-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                              <span
                                className="spinner-grow spinner-grow-sm mr-2"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </>
                          )}
                          {isRegister && !loading && "Request"}
                          {isResetPassword && !loading && "Reset"}
                          {!isResetPassword &&
                            !isRegister &&
                            !loading &&
                            "Login"}
                        </button>
                      </div>

                      {!isRegister && !isResetPassword && (
                        <div className="mt-4 text-center">
                          <span
                            className="text-muted btn"
                            onClick={() => {
                              setresetInputErrors((prevState) => !prevState);
                              setisResetPassword(true);
                            }}
                          >
                            <i className="mdi mdi-lock mr-1"></i>
                            Forgot your password?
                          </span>
                        </div>
                      )}

                      {isRegister && (
                        <div className="mt-4 text-center">
                          <div>
                            <p>
                              By registering you agree to the
                              <span className="font-weight-medium text-primary btn">
                                Terms of Use.
                              </span>
                            </p>
                          </div>
                        </div>
                      )}

                      <div className="mt-4 text-center">
                        <div>
                          <p>
                            {isResetPassword
                              ? "Remember it ?"
                              : isRegister
                              ? "Already have an account ?"
                              : "Don't have an account ?"}
                            <span
                              onClick={loginToogleHandler}
                              className="font-weight-medium text-primary btn"
                            >
                              {isRegister || isResetPassword
                                ? "Login"
                                : "Request Access"}
                            </span>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertBox open={alert.open} message={alert.message} />
    </div>
  );
}

export default Login;

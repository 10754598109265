import {
  AUTH_INIT,
  AUTH_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_TOKEN,
  AUTH_CHECK_FAIL,
} from "../../Actions/Auth";
import { SET_PROFILE_PIC, RESET_PROFILE_PIC } from "../../Actions/App";

import { UPDATE_ACCOUNT_SUCCESS } from "../../Actions/Account";

const initState = {
  id: null,
  isAdmin: false,
  username: null,
  token: null,
  email: null,
  isVerfied: null,
  checkingAuth: true,
  error: null,
  loading: false,
  profileImage: null,
};

const AuthReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    // Authentication
    case AUTH_INIT:
      return { ...state, loading: true };
    case LOGIN_SUCCESS: {
      let newState = {
        ...state,
        isAdmin: data.role.toLowerCase() === "admin",
        username: data.name,
        email: data.email,
        id: data.id,
        loading: false,
        checkingAuth: false,
      };
      if (data.jwtToken) newState.token = data.jwtToken;
      return newState;
    }

    case AUTH_CHECK_FAIL:
      return {
        ...state,
        checkingAuth: false,
      };

    case AUTH_FAIL:
      return {
        ...state,
        error: data ? data.message : "Oops, something went wrong !!",
        loading: false,
      };
    case LOGOUT:
      return {
        ...initState,
        checkingAuth: false,
      };
    case UPDATE_TOKEN:
      return { ...state, token: data, checkingAuth: false };

    //Update Profile
    case UPDATE_ACCOUNT_SUCCESS: {
      if (parseInt(data.id) === parseInt(state.id)) {
        const username = data.employerDetail.name;
        localStorage.setItem("name", username);
        return { ...state, username: username };
      }
      return state;
    }

    //Profile Image
    case SET_PROFILE_PIC:
      return { ...state, profileImage: data };
    case RESET_PROFILE_PIC:
      return { ...state, profileImage: null };

    default:
      return state;
  }
};

export default AuthReducer;

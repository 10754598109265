import React, { useState, useCallback } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

function Notifications() {
	const [show, setshow] = useState(false);

	const handleToggle = useCallback(() => {
		setshow((prevState) => !prevState);
	}, []);

	const handleClose = useCallback(() => {
		setshow(false);
	}, []);

	return (
		<ClickAwayListener onClickAway={handleClose}>
			<div className="dropdown d-inline-block">
				<button
					type="button"
					className="btn btn-sm px-3 header-item noti-icon waves-effect"
					id="page-header-notifications-dropdown"
					data-toggle="dropdown"
					aria-haspopup="true"
					aria-expanded="false"
					onClick={handleToggle}
				>
					<i className="bx bx-bell "></i>
					<span className="badge badge-danger badge-pill">3</span>
				</button>
				<div
					className={`dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 ${
						show ? "show" : ""
					}`}
					aria-labelledby="page-header-notifications-dropdown"
				>
					<div className="p-3">
						<div className="row align-items-center">
							<div className="col">
								<h6 className="m-0"> Notifications </h6>
							</div>
							<div className="col-auto">
								<a href="www.google.com!" className="small">
									{" "}
									View All
								</a>
							</div>
						</div>
					</div>
					<div
						data-simplebar
						style={{
							maxHeight: "230px",
							height: "auto",
							overflow: "hidden scroll",
						}}
						className="simplebar-content-wrapper"
					>
						<a href="www.google.com" className="text-reset notification-item">
							<div className="media">
								<div className="avatar-xs mr-3">
									<span className="avatar-title bg-primary rounded-circle font-size-16">
										<i className="bx bx-cart"></i>
									</span>
								</div>
								<div className="media-body">
									<h6 className="mt-0 mb-1">Your order is placed</h6>
									<div className="font-size-12 text-muted">
										<p className="mb-1">
											If several languages coalesce the grammar
										</p>
										<p className="mb-0">
											<i className="mdi mdi-clock-outline"></i> 3 min ago
										</p>
									</div>
								</div>
							</div>
						</a>
						<a href="www.google.com" className="text-reset notification-item">
							<div className="media">
								{/* <img src="assets/images/users/avatar-3.jpg"
                                    className="mr-3 rounded-circle avatar-xs" alt="user-pic"> */}
								<div className="media-body">
									<h6 className="mt-0 mb-1">James Lemire</h6>
									<div className="font-size-12 text-muted">
										<p className="mb-1">
											It will seem like simplified English.
										</p>
										<p className="mb-0">
											<i className="mdi mdi-clock-outline"></i> 1 hours ago
										</p>
									</div>
								</div>
							</div>
						</a>
						<a href="www.google.com" className="text-reset notification-item">
							<div className="media">
								<div className="avatar-xs mr-3">
									<span className="avatar-title bg-success rounded-circle font-size-16">
										<i className="bx bx-badge-check"></i>
									</span>
								</div>
								<div className="media-body">
									<h6 className="mt-0 mb-1">Your item is shipped</h6>
									<div className="font-size-12 text-muted">
										<p className="mb-1">
											If several languages coalesce the grammar
										</p>
										<p className="mb-0">
											<i className="mdi mdi-clock-outline"></i> 3 min ago
										</p>
									</div>
								</div>
							</div>
						</a>

						<a href="www.google.com" className="text-reset notification-item">
							<div className="media">
								{/* <img src="assets/images/users/avatar-4.jpg"
                                    className="mr-3 rounded-circle avatar-xs" alt="user-pic"> */}
								<div className="media-body">
									<h6 className="mt-0 mb-1">Salena Layfield</h6>
									<div className="font-size-12 text-muted">
										<p className="mb-1">
											As a skeptical Cambridge friend of mine occidental.
										</p>
										<p className="mb-0">
											<i className="mdi mdi-clock-outline"></i> 1 hours ago
										</p>
									</div>
								</div>
							</div>
						</a>
					</div>
					<div className="p-2 border-top">
						<a
							className="btn btn-sm btn-link font-size-14 btn-block text-center"
							href="www.google.com"
						>
							<i className="mdi mdi-arrow-right-circle mr-1"></i> View More..
						</a>
					</div>
				</div>
			</div>
		</ClickAwayListener>
	);
}

export default Notifications;

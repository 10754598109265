import {
  GET_JOBS_INIT,
  GET_JOBS_LOADING,
  GET_JOBS_SUCCESS,
  GET_JOBS_FAIL,
  DELETE_JOB,
  GET_JOB_META_SUCCESS,
  GET_JOB_DETAILS_INIT,
  GET_JOB_DETAILS_SUCCESS,
  GET_JOB_DETAILS_FAIL,
  UPDATE_JOB_META,
  DELETE_JOB_META,
  SET_QUERY_PARAMS,
} from "../../Actions/Job";
import { LOGOUT } from "../../Actions/Auth";
import { cloneDeep } from "lodash";

const initState = {
  jobList: {
    list: [],
    loading: true,
    pageNo: 1,
    size: 20,
    atLast: false,
    sortBy: null,
    sortOrder: null,
    locationFilter: null,
    categoryFilter: null,
    otherFilter: null,
  },
  jobMetaData: {
    jobTypes: {
      id: -1,
      displayString: "Job Types",
      singular: "Type",
      data: [],
    },
    skills: { id: 0, displayString: "Skills", singular: "Skill", data: [] },
    qualifications: {
      id: 2,
      displayString: "Qualifications",
      singular: "Qualification",
      data: [],
    },
    applicantSites: {
      id: 4,
      displayString: "Applicant Sites",
      singular: "Applicant site",
      data: [],
    },
    jobCategories: {
      id: 1,
      displayString: "Job Categories",
      singular: "Job category",
      data: [],
    },
    jobLocations: {
      id: 3,
      displayString: "Job Locations",
      singular: "Job location",
      data: [],
    },
  },
  jobDetails: "loading",
};

const JobReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    // Jobs
    case GET_JOBS_INIT:
      return {
        ...state,
        jobList: {
          ...state.jobList,
          loading: true,
          list: [],
          pageNo: 1,
          atLast: false,
        },
      };
    case GET_JOBS_LOADING:
      return {
        ...state,
        jobList: { ...state.jobList, loading: true },
      };
    case GET_JOBS_SUCCESS: {
      if (Boolean(!data))
        return {
          ...state,
          jobList: { ...state.jobList, atLast: true, loading: false },
        };
      const updatedList = [...state.jobList.list, ...data];
      const nextPage = state.jobList.pageNo + 1;
      return {
        ...state,
        jobList: {
          ...state.jobList,
          list: updatedList,
          loading: false,
          pageNo: nextPage,
        },
      };
    }
    case GET_JOBS_FAIL:
      return {
        ...state,
        jobList: {
          ...state.jobList,
          loading: false,
          atLast: data.message.match(/no jobs/i) ? true : false,
        },
      };

    //Job details
    case GET_JOB_DETAILS_INIT:
      return { ...state, jobDetails: "loading" };
    case GET_JOB_DETAILS_SUCCESS:
      return { ...state, jobDetails: data };
    case GET_JOB_DETAILS_FAIL:
      return { ...state, jobDetails: null };

    //Delete job
    case DELETE_JOB: {
      let updatedJobList = [...state.jobList.list];
      let delIndex = updatedJobList.findIndex(
        (job) => parseInt(job.id) === parseInt(data)
      );
      updatedJobList.splice(delIndex, 1);
      return { ...state, jobList: { ...state.jobList, list: updatedJobList } };
    }

    // Job meta data
    case GET_JOB_META_SUCCESS: {
      let updatedMeta = { ...state.jobMetaData };
      if (data.jobTypes) updatedMeta.jobTypes.data = data.jobTypes;
      if (data.skills) updatedMeta.skills.data = data.skills;
      if (data.qualifications)
        updatedMeta.qualifications.data = data.qualifications;
      if (data.applicantSites)
        updatedMeta.applicantSites.data = data.applicantSites;
      if (data.jobCategories)
        updatedMeta.jobCategories.data = data.jobCategories;
      if (data.jobLocations) updatedMeta.jobLocations.data = data.jobLocations;
      return { ...state, jobMetaData: updatedMeta };
    }

    //Update Job Meta
    case UPDATE_JOB_META: {
      let updatedMetaData = cloneDeep(state.jobMetaData);
      updatedMetaData[data.dataKey].data[data.index] = data.newValue;
      return { ...state, jobMetaData: updatedMetaData };
    }

    //Delete Job Meta
    case DELETE_JOB_META: {
      let updatedMetaData = cloneDeep(state.jobMetaData);
      updatedMetaData[data.dataKey].data.splice(data.index, 1);
      return { ...state, jobMetaData: updatedMetaData };
    }

    //Set Sorting attributes
    case SET_QUERY_PARAMS: {
      return {
        ...state,
        jobList: { ...state.jobList, ...data },
      };
    }

    // Logout
    case LOGOUT:
      return { ...initState };

    default:
      return state;
  }
};

export default JobReducer;

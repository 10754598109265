import { SET_ALERT, UNSET_ALERT, TOGGLE_LANGUAGE } from "../../Actions/App";
import { LOGOUT } from "../../Actions/Auth";

const initState = {
  alert: {
    open: false,
    message: "",
    type: null,
    onOk: () => 1,
    onCancel: () => 1,
    onDownload: () => 1,
    data: null,
  },
  profileDetails: "loading",
  lang: "en",
};

const AppReducer = (state = initState, action) => {
  const { type, data } = action;
  switch (type) {
    // Golbal alert
    case SET_ALERT:
      return { ...state, alert: { ...data, open: true } };
    case UNSET_ALERT:
      return { ...state, alert: { ...initState.alert } };

    // Toggle Languate
    case TOGGLE_LANGUAGE:
      return { ...state, lang: state.lang === "en" ? "jp" : "en" };

    // Logout
    case LOGOUT:
      return { ...initState };

    default:
      return state;
  }
};

export default AppReducer;

import React from "react";
import { validateInput } from "../../Utils/InputValidator";

export const withValidation = (WrappedInput) => (props) => {
  const { rules, onChange, resetInputError, ...rest } = props;
  const [error, seterror] = React.useState(null);

  React.useEffect(() => {
    seterror(null);
  }, [resetInputError]);

  const hanldeValidations = React.useCallback(
    (event) => {
      if (!rules) return onChange && onChange(event);
      const { value } = event.target;
      let error = null,
        isValid = true;
      for (let rule of rules) {
        error = validateInput(value, rule);
        if (error) {
          isValid = false;
          break;
        }
      }
      seterror(error);
      onChange && onChange(event, isValid);
    },
    [rules, onChange]
  );

  return <WrappedInput {...rest} onChange={hanldeValidations} error={error} />;
};

export const formatPrettyDate = (isoDate) => {
  if (!isoDate) return "";
  let prettyDate = new Date(`${isoDate}`).toDateString().split(" ");
  prettyDate.splice(0, 1);
  prettyDate.splice(2, 0, ",");
  return prettyDate.join(" ");
};

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

import React, { useState, useEffect } from "react";
import { useQuery } from "../../Hooks/useQuery";
import { verifyEmail } from "../../Store/Actions/Auth";
import { useDispatch } from "react-redux";
import AlertBox from "../Login/AlertBox";

function VerifyEmail() {
  const dispatch = useDispatch();
  const token = useQuery().get("token");
  const [alert, setalert] = useState({
    open: true,
    message: "Verifying Email !",
    loading: true,
    error: false,
  });
  useEffect(() => {
    let reqBody = {
      token: token,
    };
    dispatch(verifyEmail(reqBody))
      .then((res) =>
        setalert((prevState) => ({
          ...prevState,
          loading: false,
          message: res.message,
        }))
      )
      .catch((err) =>
        setalert((prevState) => ({
          ...prevState,
          error: true,
          loading: false,
          message: err.message,
        }))
      );
    //eslint-disable-next-line
  }, []);

  return (
    <div>
      <AlertBox {...alert} />
    </div>
  );
}

export default VerifyEmail;

export const en = {
  account: "Account",
  jobpost: "Job Post",
  dashboard: "Dashboard",
  employers: "Employers",
  applicants: "Applicants",
  job_posts: "Job Posts",
  job_master_data: "Job Master Data",
  calendar: "Calendar",
  profile: "Profile",
  logout: "Logout",
  language_switch: "Language Switch",
  welcome_back: "Welcome Back !",
  view_profile: "View Profile",
  active: "Active",
  inactive: "Inactive",
  verified: "Verified",
  unverified: "Unverified",
  counts: "Counts",
  total_employers: "Total Employers",
  total_job_posts: "Total Job Posts",
  total_applicants: "Total Applicants",
  total_applied_jobs: "Total Applied Jobs",
  personal_information: "Personal Information",
  id: "ID",
  "e-mail": "E-mail",
  add_employer: "Add Employer",
  add_applicant: "Add Applicant",
  add_job_post: "Add Job Post",
  add_master_data: "Add Master Data",
  name: "Name",
  status: "Status",
  verification: "Verification",
  actions: "Actions",
  view_details: "View Details",
  activate: "Activate",
  deactivate: "Deactivate",
  delete: "Delete",
  employer_details: "Employer Details",
  applicant_details: "Applicant Details",
  job_details: "Job Details",
  applicant_information: "Applicant Information",
  employer_information: "Employer Information",
  enter_employer_name: "Enter employer name",
  enter_employer_email: "Enter employer email",
  enter_applicant_name: "Enter applicant name",
  enter_applicant_email: "Enter applicant email",
  select_applicant_site: "Select applicant site",
  edit_employer: "Edit Employer",
  edit_applicant: "Edit Applicant",
  edit_job: "Edit Job",
  edit_details: "Edit Details",
  description: "Description",
  contact: "Contact",
  address: "Address",
  zip: "Zip Code",
  experience: "Experience",
  date_of_birth: "Date of Birth",
  add: "Add",
  cancel: "Cancel",
  save: "Save",
  confirm: "Confirm",
  ok: "Ok",
  done: "done",
  submit: "Submit",
  edit: "Edit",
  upload: "Upload",
  download: "Download",
  select: "Select",
  not_available: "N/A",
  loading: "Loading",
  account_settings: "Account Settings",
  credentials: "Credentials",
  password: "Password",
  new_password: "New Password",
  confirm_new_password: "Confirm New Password",
  job_form: "Job Form",
  employer: "Employer",
  summary: "Summary",
  skills: "Skills",
  skill: "Skill",
  date_added: "Date Added",
  salary: "Salary",
  responsibilities: "Responsibilities",
  add_responsibility: "Add Responsibility",
  job_type: "Job Type",
  job_categories: "Job Categories",
  job_category: "Job Category",
  min_salary: "Min Salary",
  max_salary: "Max Salary",
  experience_required: "Experience Required",
  hours_per_week: "Hours/Week",
  qualifications: "Qualifications",
  qualification: "Qualification",
  job_locations: "Job Locations",
  job_location: "Job Location",
  applicant_sites: "Applicant Sites",
  applicant_site: "Applicant Site",
  publish_on: "Publish on",
  select_employer: "Select Employer",
  enter_job_summary: "Enter Job Summary",
  enter_job_description: "Enter Job Description",
  select_job_type: "Select job type",
  select_job_category: "Select job category",
  enter_minimum_salary: "Enter minimum salary",
  enter_maximum_salary: "Enter maximum salary",
  enter_minimum_experience_required: "Enter minimum experience required",
  enter_hours_per_week: "Enter hours per Week",
  enter_skill: "Enter Skill",
  enter_qualification: "Enter qualification",
  enter_job_location: "Enter job location",
  enter_applicant_site: "Enter applicant sites",
  job_data: "Job Data",
  data_type: "Data Type",
  select_data_type: "Select data type",
  data_list: "Data List",
  enter_data: "Enter Data",
  add_more_data: "Add more data",
  no_results_found: "No Results Found",
  no_more_results_found: "No More Results Found !",
  applied_jobs: "Applied Jobs",
  current_salary: "Current Salary",
  expected_salary: "Expected Salary",
  keyword: "Keyword",
  filter: "Filter",
  filters: "Filters",
  export_jobs: "Export Jobs",
  export_applicants: "Export Applicants",
  export_employers: "Export Employers",
  exporting_jobs: "Exporting jobs ...",
  exporting_applicants: "Exporting applicants ...",
  exporting_employers: "Exporting employers ...",
  exported_file_is_ready_for_download: "Exported file is ready for download",
  unable_to_export: "Unable to export",

  // ==== Alerts =================================================================
  //Eg. => "Are you sure you want to delete Rahul ?" ( here,  Rahul => %%entity%%)
  //Eg. =>  "Are you sure you want to add job ?" ( here,  job => %%entity%%)
  "confirm_update_%%entity%%": "Are you sure, you want to update %%entity%% ?",
  "confirm_delete_%%entity%%": "Are you sure, you want to delete %%entity%% ?",
  "confirm_activate_%%entity%%":
    "Are you sure, you want to activate %%entity%% ?",
  "confirm_deactivate_%%entity%%":
    "Are you sure, you want to deactivate %%entity%% ?",
  "updating_%%entity%%": "Updating %%entity%% ...",
  "deleting_%%entity%%": "Deleting %%entity%% ...",
  "adding_%%entity%%": "Adding %%entity%% ...",
  "activating_%%entity%%": "Activating %%entity%% ...",
  "deactivating_%%entity%%": "Deactivating %%entity%% ...",
  "%%entity%%_updated": "%%entity%% updated !",
  "%%entity%%_deleted": "%%entity%% deleted !",
  "%%entity%%_added": "%%entity%% Added !",
  "%%entity%%_activated": "%%entity%% activated !",
  "%%entity%%_deactivated": "%%entity%% deactivated !",
  //==============================================================================

  //basic detais start
  basic_information: "Basic Informations",
  member_registration: "Member registration",
  edit_profile_page_heading: "Easy step So, to Free registration!",
  email_address: "Email",
  first_name: "First Name",
  last_name: "Last Name",
  first_katakana_name: "First Katakana Name",
  last_katakana_name: "Last Katakana Name",
  birthday: "Birthday",
  sex: "Sex",
  male: "Male",
  female: "Female",
  address_postal_code: "Address Postal Code",
  prefecture: "Prefecture",
  country: "Country",
  city: "City",
  nearest_station: "Nearest Station",
  route_name: "Route Name",
  station_name: "Station Name",
  telephone_number: "Telephone Number",
  current_employment_status: "Current Employment Status",
  employment_status: "Employment status",
  full_time_employee: "full-time Employee",
  contract_employee: "Contract employee",
  dispatched_labor: "Dispatched Labor",
  part_time_job: "part-time job",
  Subcontracting: "Subcontracting",
  self_employed_fc_owner: "Self-employed / FC owner",
  turnover_in: "Turnover in",
  other: "Other",
  search: "Search",
  katakana_name: "Katana Name",
  //Work history / educational background start
  work_history_educational_background: "Work history / educational background",
  experience_in_occupation: "Experience in occupation",
  number_of_working_companies: "Number of working companies",
  final_education: "Final Education",
  junior_high_school: "Junior high school",
  high_school: "High School",
  technical_college: "Technical College",
  vocational_school: "Vocational school",
  junior_college: "Junior college",
  university: "University",
  grad_student: "Grad Student",
  graduation: "Graduation",
  student: "Student",
  drop_out: "Drop Out",
  school_name: "School Name",
  please_enter_your_school_name: "Please enter your school name",
  graduation_date: "Graduation date",
  //Work history / educational background end

  //Qualifications / skills start
  qualifications_skills: "Qualifications / skills",
  pc_skills: "PC skills",
  word: "Word",
  no_experience: "No experience",
  word_op_one: "Beginner ... I have created and saved documents using word",
  word_op_two:
    "Beginner: Can create, edit, save, and print basic business documents",
  word_op_three:
    "Intermediate: Can create tables, set various formats, and create documents using simple figures and images.",
  word_op_four:
    "Advanced: Can create documents and mail merge using external data, etc.",
  excel: "Excel",
  excel_op_one: "Beginner ... I have created a table using Excel",
  excel_op_two:
    "Beginner ・ ・ ・ Basic table creation, graph creation and calculation formula input",
  excel_op_three:
    "Intermediate: Can create IF functions, pivot tables, composite graphs, and aggregate data",
  excel_op_four:
    "Advanced: You can use various functions and create data using macros and VBA.",
  access: "Access",
  access_op_one: "Beginner ... I have used Access to input and extract data.",
  access_op_two: "Beginner: Input / print and data extraction / sorting etc.",
  access_op_three:
    "Intermediate: Can edit tables, forms, reports, and create queries.",
  access_op_four:
    "Advanced: You can create and utilize databases using macros and VBA.",
  language_skill: "Language skills",
  english_conversation: "English conversation",
  english_con_op_one: "Can't / almost can't",
  english_con_op_two: "Not very good at",
  english_con_op_three: "You can make a phone call",
  english_con_op_four: "Can do daily conversation",
  english_con_op_five: "Have a business conversation level",
  english_reading_comprehension: "English reading comprehension",
  english_read_op_one: "Can't / almost can't",
  english_read_op_two: "Not very good at",
  english_read_op_three: "Can read simple documents",
  english_read_op_four: "Can read business documents",
  english_read_op_five: "Can translate",
  toeic: "TOEIC",
  toefl: "TOEFL",
  eiken: "Eiken",
  level_one: "Level 1",
  level_two: "Level 2",
  semi_second_grade: "Semi-second grade",
  level_three: "Level 3~",
  none: "None",
  never_received: "Never received",
  other_languages: "Other languages",
  other_languages_skill: "Other languages skill",
  business_related_qualifications: "Business related qualifications",
  other_appeal_points: "Other appeal points",

  //Desired condition
  desired_condition: "Desired condition",
  desired_occupation: "Desired occupation",
  desired_occu_op_one: "Office / office system",
  desired_occu_op_two: "Factory / light work / logistics / civil engineering",
  desired_occu_op_three: "IT / engineer system",
  desired_occu_op_four: "Sales / Sales / Food / Education",
  desired_occu_op_five: "WEB / creator system",
  desired_work_region: "Desired work Region",
  unit_price: "Unit Price",
  hourly_wage: "Hourly wage",
  daily_salary: "Daily salary",
  monthly_salary: "Monthly salary",
  annual_salary: "Annual salary",
  volume_system: "Volume system",
  desired_working_hours: "Desired working hours",
  working_hours_op_one: "Not specified",
  working_hours_op_two: "3 months or more (long-term)",
  working_hours_op_three: "1 to 3 months",
  working_hours_op_four: "1 month or less",
  working_hours_op_five: "10 days or less",
  desired_work_start_date: "Desired work start date",
  desired_work_op_one: "Anytime is possible",
  desired_work_op_two: "There is a desired date",
  other_desired_conditions: "Other Desired Conditions",
};

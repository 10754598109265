import { Api } from "../Api";

// Global Alerts
export const SET_ALERT = "SET_ALERT";
export const UNSET_ALERT = "UNSET_ALERT";

export const setAlert = (data) => ({
  type: SET_ALERT,
  data: data,
});
export const unsetAlert = () => ({
  type: UNSET_ALERT,
});
export const failureAlert = (message) => (dispatch) =>
  dispatch(
    setAlert({
      type: "error",
      message: `${message ? message : "Something went wrong !"}`,
      onOk: () => dispatch(unsetAlert()),
    })
  );

// Get Profile picture
export const RESET_PROFILE_PIC = "RESET_PROFILE_PIC";
export const SET_PROFILE_PIC = "SET_PROFILE_PIC";
export const getProfilePicture = (id) =>
  Api({
    url: `/Accounts/get-profile-photo/${id}`,
    method: "GET",
    onInit: resetProfilePic,
    onSuccess: setProfilePic,
    onFailure: resetProfilePic,
  });
const setProfilePic = (response) => ({
  type: SET_PROFILE_PIC,
  data: response,
});
export const resetProfilePic = () => ({
  type: RESET_PROFILE_PIC,
});

// Post Profile picture
export const postProfilePicture = (id, formData) =>
  Api({
    url: `/Accounts/update-profile-photo/${id}`,
    method: "PUT",
    data: formData,
  });

// Toogle language
export const TOGGLE_LANGUAGE = "TOGGLE_LANGUAGE";
export const changeLanguage = () => ({
  type: TOGGLE_LANGUAGE,
});

import React from "react";
import { withValidation } from "../../../HOCs/withValidations";
import { useTranslator } from "../../../Hooks/useTranslator";
import { get as getFromObject } from "lodash";

export const Input = ({
  error,
  element,
  className,
  options,
  optionsConfig,
  ...rest
}) => {
  const t = useTranslator();
  let styleClasses = `form-control no-arrow ${className} ${
    error ? "is-invalid" : ""
  }`;

  const InputElement = React.useMemo(() => {
    switch (element) {
      case "input":
        return <input {...rest} className={styleClasses} />;

      case "textarea":
        return <textarea {...rest} className={styleClasses} />;

      case "select":
        return (
          <select {...rest} className={`${styleClasses} custom-select`}>
            {options.length === 0 ? (
              <option value="">{t("Loading")}...</option>
            ) : (
              <>
                <option value="">{t(rest.placeholder)}</option>
                {options.map((option) => (
                  <option
                    value={
                      optionsConfig
                        ? getFromObject(option, optionsConfig.valueField)
                        : option
                    }
                    key={
                      optionsConfig
                        ? getFromObject(option, optionsConfig.valueField)
                        : option
                    }
                  >
                    {optionsConfig
                      ? getFromObject(option, optionsConfig.labelField)
                      : option}
                  </option>
                ))}
              </>
            )}
          </select>
        );

      default:
        return <input {...rest} className={styleClasses} />;
    }
  }, [element, options, rest, styleClasses, t, optionsConfig]);

  return (
    <>
      {InputElement}
      <div className="invalid-feedback">{error}</div>
    </>
  );
};

export default withValidation(Input);

import React from "react";
import Loader from "../../../assets/loader";

function AlertBox(props) {
	const { open, message, loading, error } = props;
	return (
		open && (
			<div
				className="swal2-container swal2-center swal2-shown"
				style={{ overflowY: "auto" }}
			>
				<div
					aria-labelledby="swal2-title"
					aria-describedby="swal2-content"
					className="swal2-popup swal2-modal swal2-shown"
					tabIndex="-1"
					role="dialog"
					aria-live="assertive"
					aria-modal="true"
					style={{ display: "flex" }}
				>
					<div className="swal2-header">
						{!loading && !error && (
							<div
								className="swal2-icon swal2-success swal2-animate-success-icon"
								style={{ display: "flex" }}
							>
								<div
									className="swal2-success-circular-line-left"
									style={{ backgroundColor: "rgb(255, 255, 255)" }}
								></div>
								<span className="swal2-success-line-tip"></span>
								<span className="swal2-success-line-long"></span>
								<div className="swal2-success-ring"></div>
								<div
									className="swal2-success-fix"
									style={{ backgroundColor: "rgb(255, 255, 255)" }}
								></div>
								<div
									className="swal2-success-circular-line-right"
									style={{ backgroundColor: "rgb(255, 255, 255)" }}
								></div>
							</div>
						)}

						{error && (
							<div
								className="swal2-icon swal2-error"
								style={{ display: "flex" }}
							>
								<span className="swal2-x-mark">
									<span className="swal2-x-mark-line-left"></span>
									<span className="swal2-x-mark-line-right"></span>
								</span>
							</div>
						)}
						<h2
							className="swal2-title"
							id="swal2-title"
							style={{ display: "flex" }}
						>
							{message}
						</h2>
					</div>
					{!loading && (
						<div className="swal2-content">
							<div id="swal2-content" style={{ display: "block" }}>
								Return to login
							</div>
						</div>
					)}

					{loading && <Loader />}

					{!loading && (
						<div className="swal2-actions">
							<button
								type="button"
								className="swal2-confirm swal2-styled"
								aria-label=""
								style={{
									display: "inline-block",
								}}
								onClick={() => window.location.replace("/")}
							>
								Login
							</button>
						</div>
					)}
				</div>
			</div>
		)
	);
}

export default AlertBox;

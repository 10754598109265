import React from "react";
import Notifications from "./Notifications";
import ProfileDropdown from "./ProfileDropdown";
import { Link } from "react-router-dom";
import lang_en from "../../../assets/images/us.png";
import lang_jp from "../../../assets/images/japan.png";
import { useSelector, useDispatch } from "react-redux";
import { changeLanguage } from "./../../../Store/Actions/App";
import { useTranslator } from "../../../Hooks/useTranslator";

function Header(props) {
  const { onSidebarToogle } = props;
  const dispatch = useDispatch();
  const t = useTranslator();
  const { lang } = useSelector((store) => store.app);

  const handleLanguageChange = React.useCallback(
    () => dispatch(changeLanguage()),
    [dispatch]
  );
  return (
    <header id="page-topbar">
      <div className="navbar-header">
        <div className="d-flex w-100 ">
          <div className="navbar-brand-box">
            <span className="logo logo-dark">
              <span className="logo-sm">
                {/* <img src="assets/images/logo.svg" alt="" height="22"> */}
                JP
              </span>
              <span className="logo-lg">
                {/* <img src="assets/images/logo-dark.png" alt="" height="17"> */}
                JP
              </span>
            </span>

            <span className="logo logo-light">
              <Link to="/">
                <span className="logo-sm">
                  {/* <img src="assets/images/logo-light.svg" alt="" height="22"> */}
                  JP
                </span>
                <span className="logo-lg text-white font-size-20">
                  <i className="mdi mdi-briefcase-account"></i> Job Portal
                </span>
              </Link>
            </span>
          </div>

          <button
            type="button"
            className="btn btn-sm px-3 font-size-16 header-item waves-effect"
            id="vertical-menu-btn"
            onClick={onSidebarToogle}
          >
            <span className="logo-sm">
              <i className="fa fa-fw fa-bars"></i>
            </span>
          </button>

          <div className="navbar-user-box d-flex align-items-end flex-grow-1 justify-content-end">
            <div className="dropdown d-inline-block">
              <button
                type="button"
                className="btn header-item waves-effect"
                title={t("Language Switch")}
                onClick={handleLanguageChange}
              >
                <img
                  className=""
                  src={lang === "en" ? lang_jp : lang_en}
                  alt="Header Language"
                  height="24"
                />
              </button>
            </div>
            <Notifications />
            <ProfileDropdown />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;

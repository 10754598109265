import React, { useState, useCallback, useEffect } from "react";
import { useWindowSize } from "../../Hooks/useWindowSize";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Alert from "../../Components/Common/Alert";

function Landing(props) {
  const { children } = props;
  const { width } = useWindowSize();
  const [sidebarOpen, setsidebarOpen] = useState(window.innerWidth >= 992);

  useEffect(() => {
    if (width < 992) setsidebarOpen(false);
    else setsidebarOpen(true);
  }, [width]);

  const handleSidebarToogle = useCallback(() => {
    setsidebarOpen((prevState) => !prevState);
  }, []);

  const handleSidebarClose = useCallback(() => {
    setsidebarOpen(false);
  }, []);

  return (
    <div id="layout-wrapper">
      <Header onSidebarToogle={handleSidebarToogle} />
      <Sidebar open={sidebarOpen} onClose={handleSidebarClose} />
      <div className={`main-content ${sidebarOpen ? "" : "ml-0"}`}>
        <div className="page-content">
          <div className="container-fluid">{children}</div>
        </div>
      </div>
      <Alert />
    </div>
  );
}

export default Landing;
